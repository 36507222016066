import Vue from 'vue';
import store from '@/store';
import {
	userLogIn
} from '@/api/user.js';
import {
	getUserInfo
} from '@/api/weChart.js';

import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
		path: '/', //我的医院
		alias: '/home',
		name: 'Home',
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import( /* webpackChunkName: "about" */ '../views/Home.vue')
	},
	{
		path: '/self', //个人主页
		name: 'Self',
		component: () => import('../views/Self.vue')

	},
	{
		//0为查询默认磁卡号码
		path: '/check/:ckhm',
		name: 'Check3',
		component: () => import('../views/Check.vue')
	},
	{
		path: '/checkPhoneNumber', //手机号码验证
		name: 'CheckPhone',
		component: () => import('../views/CheckPhoneNumber.vue')
	},
	{
		path: '/depts', //科室列表
		name: 'Departments',
		component: () => import('../views/Departments.vue')
	},
	{
		path: '/doctors/:departmentId', //医生列表
		name: 'Doctors',
		component: () => import('../views/Doctors.vue')
	},
	{
		path: '/doctorInfo/:doctorId/:departmentId', //医生详情
		name: 'DoctorInfo',
		component: () => import('../views/DoctorInfo.vue')
	},
	{
		//pageStatus: 0 正常|1 重填表单数据|2 订单提交成功|3 订单提交异常
		path: '/consultOnline/:pageStatus/:departmentId/:doctorId', //线上咨询
		name: 'ConsultOnline',
		component: () => import('../views/ConsultOnline.vue')
	},
	{
		path: '/address/:departmentId/:doctorId', //我的地址
		name: 'Address',
		component: () => import('../views/Address.vue')
	},
	{
		path: '/addAddress/:departmentId/:doctorId/:addressId', //添加地址
		name: 'AddAddress',
		component: () => import('../views/AddAddress.vue')
	},
	{ //用户身份证上传
		path: '/idCardUpload',
		name: 'IdCardUpload',
		component: () => import('../views/IdCardUpload.vue')
	},
	{ //用户身份信息确认
		path: '/idCardConfirm',
		name: 'IdCardConfirm',
		component: () => import('../views/IdCardConfirm.vue')
	},
	{ //用户病历资料上传
		path: '/medicalRecordUpload',
		name: 'MedicalRecordUpload',
		component: () => import('../views/MedicalRecordUpload.vue')
	},
	{ //院内账户判断
		path: '/accountJudgment/:selectIndex/:departmentId/:doctorId',
		name: 'AccountJudgment',
		component: () => import('../views/AccountJudgment.vue')
	},
	{ //院内账户详细信息
		path: '/accountInformation/:selectIndex/:cardId',
		name: 'AccountInformation',
		component: () => import('../views/AccountInformation.vue')
	},
	{ //在线充值
		path: '/recharge',
		name: 'Recharge',
		component: () => import('../views/Recharge.vue')
	},
	{ //在线充值
		path: '/recharge/:status',
		name: 'Recharge2',
		component: () => import('../views/Recharge.vue')
	},
	{ //在线退款
		path: '/refund/:selectIndex/:cardId',
		name: 'Refund',
		component: () => import('../views/Refund.vue')
	},
	{ //药品物流
		path: '/logistics',
		name: 'Logistics',
		component: () => import('../views/Logistics.vue')
	},
	{ //药品物流详情
		path: '/logisticsDetails/:treatmentNum',
		name: 'LogisticsDetails',
		component: () => import('../views/LogisticsDetails.vue')
	},
	{ //就诊记录
		path: '/visit',
		name: 'Visit',
		component: () => import('../views/Visit.vue')
	},
	{ //检查单
		path: '/report',
		name: 'Reoprt',
		component: () => import('../views/Report.vue')
	},
	{ //就诊记录明细
		path: '/visitmx/:jzxh/:pd/:ddzt',
		name: 'Visitmx',
		component: () => import('../views/VisitMX.vue')
	},
	{ //我的咨询
		path: '/consult/:targetId/:doctor',
		name: 'Consult',
		component: () => import('../views/Consult.vue')
	},
	{ //消息
		path: '/message',
		name: 'Message',
		component: () => import('../views/Message.vue')
	},
	{ //
		path: '/reportsm/:xmmc/:xm/:rq/:txm/:pd/:sampleno',
		name: 'ReportSM',
		component: () => import('../views/ReportSM.vue')
	},
	{ //
		path: '/encore/:jzxh',
		name: 'Encore',
		component: () => import('../views/Encore.vue')
	},
	{ //
		path: '/reportsm/:tmh',
		name: 'ReportSM2',
		component: () => import('../views/ReportSM.vue')
	},

	{
		//0为查询默认磁卡号码
		path: '/check/:ckhm/:brxm/:xzmc/',
		name: 'Check',
		component: () => import('../views/Check.vue')
	},
	{
		//0为查询默认磁卡号码
		path: '/check/:ckhm/:brxm/:xzmc/:pd',
		name: 'Check2',
		component: () => import('../views/Check.vue')
	},
	{
		path: '/addAddress/:jzxh/:xm/:dz1/:dz2/:dz3/:dz4/:dz5/:phone', //添加地址
		name: 'AddAddress2',
		component: () => import('../views/AddAddress.vue')
	},
	{ //
		path: '/encore/:jzxh/:xm/:dz1/:dz2/:dz3/:dz4/:dz5/:phone',
		name: 'Encore2',
		component: () => import('../views/Encore.vue')
	},
	{ //
		path: '/encore/:jzxh/:brxx',
		name: 'Encore3',
		component: () => import('../views/Encore.vue')
	},
	{ //
		path: '/systemmessage/:patientID',
		name: 'SystemMessage',
		component: () => import('../views/SystemMessage.vue')
	},
	{ //我的咨询
		path: '/consult',
		name: 'Consults',
		component: () => import('../views/Consult.vue')
	},
	{ //我的咨询
		path: '/decrypt',
		name: 'Decrypt',
		component: () => import('../views/DecryptData.vue')
	},
]

const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
});
router.beforeEach((to, from, next) => {
	let ignoreLoginPage = ['', 'home', 'self', 'depts', 'doctors', 'decrypt'];
	if (!store.getters.userInfo.UserId && to.path !== '/checkPhoneNumber') {
		//未登录且非绑定页面 -> 尝试登陆
		//检查手机号
		let phoneNumber = localStorage.getItem('userPhoneNumber');
		if (phoneNumber) {
			//云医院已绑定手机号码 无userID -> 登录
			userLogIn(phoneNumber, store.getters.openId).then(
				(result) => {
					localStorage.setItem('access_token', result.token)
					localStorage.setItem('access_refresh', result.refresh)
					store.dispatch('user/setLogInfo', {
						phoneNumber: phoneNumber,
						userId: result.userId
					});
					next();
				}, () => {});
			next(false);
			return;
		}

		//未绑定手机号，获取用户授权信息
		getUserInfo(store.getters.openId).then(
			(result) => {
				let data = result[0];

				//授权信息正确性校验
				if (!data || !data.NickName || !data.PhoneNumber) {
					//未查询到授权数据 //未授权 //微信授权中未绑定手机号
					if (data) {
						//存储授权信息
						store.dispatch('user/setUserInfo', data);
					}

					if (ignoreLoginPage.some(page => {
							return to.path.split('/')[1] == page
						})) {
						//访问主页不强制校验
						next();
					} else {
						//业务页面强制校验手机号码
						next('/checkPhoneNumber');
					}
					return;
				}


				//系统登陆
				userLogIn(data.PhoneNumber, store.getters.openId).then(
					(result) => {
						localStorage.setItem('access_token', result.token)
						localStorage.setItem('access_refresh', result.refresh)
						store.dispatch('user/setLogInfo', {
							phoneNumber: data.PhoneNumber,
							userId: result.userId
						});
						next();
					}, () => {});
			}, () => {
				next('/checkPhoneNumber');
			});
		next(false);
		return;
	}

	if (store.getters.userInfo.UserId && to.path == '/checkPhoneNumber') {
		//已登陆又访问手机绑定页面 -> 去主页
		next('/');
	}
	next();
});

export default router